.aboutUs {
  padding: 60px 20px;
  background-color: #0B0E0F;
  color: #ffffff;
}

.aboutUs h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
}

.description {
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
}

.description p {
  font-size: 18px;
  line-height: 1.6;
}

.features {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.featureItem {
  flex: 1;
  background-color: #1A1E1F;
  border: 1px solid #2A2E2F;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.featureItem h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.featureItem p {
  font-size: 16px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
  }

  .featureItem {
    margin-bottom: 20px;
  }
}
