:root {
  --main-bg-color: #0B0E0F;
  --max-content-width: 1920px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: var(--main-bg-color);
  color: #ffffff;
}

.App {
  text-align: center;
}

.content-wrapper {
  max-width: var(--max-content-width);
  margin: 0 auto;
}

/* Hero section */
.hero {
  height: 100vh;
  background-image: url('./images/graphic.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Services section */
.services {
  background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
  padding: 4rem 0;
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Trading System section */
.trading-system {
  background: linear-gradient(135deg, #2c3e50, #4ca1af);
  padding: 4rem 0;
}

/* Partners section */
.partners {
  background-color: #2c3e50;
  padding: 4rem 0;
}

.partners-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

/* Risk Management section */
.risk-management {
  background: linear-gradient(135deg, #3a6186, #89253e);
  padding: 4rem 0;
}

.risk-management-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Team Advantages section */
.team-advantages {
  background: linear-gradient(135deg, #1f4037, #99f2c8);
  padding: 4rem 0;
}

/* About Us and Contact sections */
.about-us,
.contact {
  background-color: var(--main-bg-color);
  padding: 4rem 0;
}
