.teamAdvantages {
  padding: 80px 20px;
  background-color: #0B0E0F;
  color: #ffffff;
}

.teamAdvantages h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
  color: #FFD700;
}

.content {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.imageSection {
  flex: 1;
}

.teamImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.advantagesSection {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.advantage {
  background: linear-gradient(145deg, #1A1E1F, #2A2E2F);
  border-radius: 15px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.advantage:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.advantage h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #FFD700;
}

.advantage p {
  font-size: 14px;
  line-height: 1.6;
  color: #CCCCCC;
}

@media (max-width: 968px) {
  .content {
    flex-direction: column;
  }

  .imageSection {
    margin-bottom: 40px;
  }

  .teamImage {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .advantagesSection {
    grid-template-columns: 1fr;
  }
}
