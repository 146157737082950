.partners {
  padding: 80px 20px;
  background-color: #0B0E0F;
  color: #ffffff;
}

.partners h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
  color: #FFD700;
}

.partnerGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.partnerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.partnerLogo img {
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
  filter: grayscale(100%) brightness(200%);
  transition: all 0.3s ease;
}

.partnerLogo:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.partnerLogo:hover img {
  filter: grayscale(0%) brightness(100%);
}

@media (max-width: 1024px) {
  .partnerGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .partnerGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
