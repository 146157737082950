.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #0B0E0F;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navLinks a {
  color: #ffffff;
  text-decoration: none;
  margin-left: 1rem;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

.mobileMenu {
  display: none;
}

.logoImage {
  max-height: 30px;  /* 根据需要调整高度 */
  width: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .navLinks {
    display: none;
  }

  .menuButton {
    display: block;
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #0B0E0F;
    padding: 1rem;
  }

  .mobileMenu a {
    color: #ffffff;
    text-decoration: none;
    padding: 0.5rem 0;
  }
}
