.contact {
  /* 现有的 Contact 样式 */
}

.footer {
  width: 100%;
}

.footerBackground {
  background-color: #1A1E1F;
  width: 100%;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 40px; /* 增加左右 padding */
  max-width: 1920px;
  margin: 0 auto;
}

.logoSection .footerLogo {
  width: 120px; /* 设置 logo 固定宽度 */
  height: auto; /* 保持宽高比 */
}

.copyrightSection {
  color: #ffffff;
  font-size: 14px;
}

.socialSection {
  display: flex;
  gap: 20px; /* 调整图标之间的间距 */
}

.socialLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* 调整大小 */
  height: 50px; /* 调整大小 */
  border: 2px solid #ffffff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.socialIcon {
  font-size: 24px; /* 调整图标大小 */
  color: #ffffff;
}

.socialLink:hover {
  background-color: #ffffff;
}

.socialLink:hover .socialIcon {
  color: #0B0E0F; /* 悬停时图标颜色变为深色 */
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    padding: 60px 80px;
    height: auto;
  }
}
