.service {
  padding: 80px 20px;
  background-color: #0B0E0F;
  color: #ffffff;
}

.service h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
  color: #FFD700;
}

.serviceContainer {
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.serviceCard {
  flex: 1;
  background: linear-gradient(145deg, #1A1E1F, #2A2E2F);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.serviceCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 48px;
  color: #FFD700;
  margin-bottom: 20px;
}

.serviceCard h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #FFD700;
}

.serviceCard p {
  font-size: 16px;
  line-height: 1.6;
  color: #CCCCCC;
}

@media (max-width: 768px) {
  .serviceContainer {
    flex-direction: column;
  }

  .serviceCard {
    margin-bottom: 30px;
  }
}
