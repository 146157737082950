.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0B0E0F; /* 保留一个背景色，以防图片加载失败 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(11, 14, 15, 0.82) 0%, rgba(24, 31, 33, 0) 100%);
  transform: translateX(-25%) scale(1.5);
  pointer-events: none;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  max-width: 800px;
  padding: 0 20px;
}

.logo {
  width: 120px;
  margin-bottom: 30px;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #FFD700;
}

.hero p {
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 36px;
  }

  .hero p {
    font-size: 16px;
  }
}
